@import "./assets/Fonts/import-fonts.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .MuiTableHead-root {
    background: #00629b;
  }
  .MuiTableCell-head{
    font-family:'Roboto', sans-serif;
    color: white !important;
    font-weight: 600;
  }
  .MuiTableCell-root {
    font-family:'Roboto', sans-serif;
  }
  .MuiFormControlLabel-label{
    font-family:'Roboto', sans-serif;
  }
  .MuiButton-label{
    font-family:'Roboto', sans-serif;
  }
  [class*="MuiTableSortLabel-active-"], [class*="MuiTableSortLabel-icon-"], [class*="MuiTableSortLabel-root-"]
  {color:white !important;}

  [class*="MuiFormControl-root-"] {
    margin: 0rem .5rem .75rem 0rem;
  }
}